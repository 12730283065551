import * as Yup from "yup";
import { companyFormFields, directorFields } from "./data";

// Generate company field validations dynamically
const companyValidationSchema = Yup.object().shape({
  ...Object.fromEntries(
    companyFormFields.map((field) => [
      field.name,
      field.required
        ? field.type === "file"
          ? Yup.mixed().required(`${field.placeholder} is required`)  // Required file field
          : Yup.string().required(`${field.placeholder} is required`)  // Required text field
        : Yup.mixed().nullable()  // Optional field: allow null values without triggering validation error
    ])
  ),

  // Validate directors array dynamically
  directors: Yup.array()
    .of(
      Yup.object().shape(
        Object.fromEntries(
          directorFields.map((field) => [
            field.name,
            field.required
              ? Yup.string().required(`${field.placeholder} is required`)  // Required director field
              : Yup.string().nullable(),  // Optional director field
          ])
        )
      )
    )
    .min(1, "At least one director is required"),  // At least one director must be present
});

export default companyValidationSchema;
