import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid"; // Import MUI Grid
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LayoutBox from "../../components/LayoutBox";
import LayoutTypography from "../../components/LayoutTypography";
import LayoutBadge from "../../components/LayoutBadge";

import DashboardLayout from "../../widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../widgets/Navbars/DashboardNavbar";
import Footer from "../../widgets/Footer";
import Table from "../../widgets/Tables/Table";

import loanTableData, { Function, LoansTaken } from "./data/loansTableData";
import loanTrackingTableData from "./data/loanTrackingTableData";

import { myLoans } from "../../store/loan.store";
import logo from "../../assets/images/logo.png";

function Tables() {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const my_loans = useSelector((state) => state.loans.my_loans);
  const { columns } = loanTableData;
  const { columns: prCols } = loanTrackingTableData;

  useEffect(() => {
    dispatch(myLoans());
  }, [dispatch]);

  useEffect(() => {
    if (my_loans?.length > 0) {
      const data = my_loans.map((loan) => {
        const startDate = new Date(loan.created_at).toLocaleDateString();
        const requestedAmount = parseFloat(loan.requested_amount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        return {
          LoansTaken: <LoansTaken image={logo} name={loan.user_name} email={loan.user_email} />,
          loanInfo: <Function job={loan.loan_purpose} org={requestedAmount} />,
          status: (
            <LayoutBadge
              variant="gradient"
              badgeContent={loan.status}
              color={loan.status === "New" ? "success" : "secondary"}
              size="xs"
              container
            />
          ),
          startDate: (
            <LayoutTypography variant="caption" color="secondary" fontWeight="medium">
              {startDate}
            </LayoutTypography>
          ),
          action: (
            <LayoutTypography
              component="a"
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              View
            </LayoutTypography>
          ),
        };
      });

      setRows(data);
    }
  }, [my_loans]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <LayoutBox py={3}>
        {my_loans?.length > 0 ? (
          <Grid container spacing={3}>
            {/* Loans Table */}
            <Grid item xs={12} md={6}>
              <Card>
                <LayoutBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <LayoutTypography variant="h6">Loans Table</LayoutTypography>
                </LayoutBox>
                <LayoutBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </LayoutBox>
              </Card>
            </Grid>

            {/* Loan Tracking Table */}
            <Grid item xs={12} md={6}>
              <Card>
                <LayoutBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <LayoutTypography variant="h6">Loan Tracking</LayoutTypography>
                </LayoutBox>
                <LayoutBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={prCols} rows={[]} />
                </LayoutBox>
              </Card>
            </Grid>
          </Grid>
        ) : (
          // Show this if no loans are found
          <LayoutBox textAlign="center" py={5}>
            <Card
              sx={{
                flex: 1,
                p: 4,
                borderRadius: 2,
              }}
            >
              <LayoutTypography variant="h6" color="textSecondary">
                No loans found. Apply for a loan to view details.
              </LayoutTypography>
            </Card>
          </LayoutBox>
        )}
      </LayoutBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
