import { styled } from "@mui/material/styles";

const CustomFileInputRoot = styled("input")(({ theme, ownerState }) => ({
  display: "block",
  width: "100%",
  padding: "10px",
  fontSize: "14px",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: "6px",
  cursor: "pointer",
  transition: "border-color 0.2s ease-in-out",
  
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },

  "&:focus": {
    outline: "none",
    borderColor: theme.palette.primary.dark,
    boxShadow: `0 0 4px ${theme.palette.primary.light}`,
  },

  "&:disabled": {
    backgroundColor: theme.palette.grey[200],
    cursor: "not-allowed",
  },

  // Custom Styles Based on OwnerState
  ...(ownerState.error && {
    borderColor: theme.palette.error.main,
    "&:focus": {
      boxShadow: `0 0 4px ${theme.palette.error.light}`,
    },
  }),

  ...(ownerState.success && {
    borderColor: theme.palette.success.main,
    "&:focus": {
      boxShadow: `0 0 4px ${theme.palette.success.light}`,
    },
  }),

  ...(ownerState.darkMode && {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[700]}`,
  }),
}));

export default CustomFileInputRoot;
