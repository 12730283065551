import { alpha } from "@mui/material";


const appBar = {
  defaultProps: {
    color: alpha("rgba(0,0,0,0)", 0.001),
  },

  styleOverrides: {
    root: {
      boxShadow: "none",
    },
  },
};

export default appBar;
