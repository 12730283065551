import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getFromLocalStorage } from "../utils/local-storage";
import Header from "./Header";
import { Briefcase, Person } from "react-bootstrap-icons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import loanDetails from "../assets/data/loans.json";

const LoanDetails = () => {
    const user = getFromLocalStorage("user");
  const navigate = useNavigate();
  const location = useLocation();

  // Get search parameters from the URL
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id"); //
  // Find the loan detail that matches the given ID
  const loanDetail = loanDetails.find((loan) => loan.id === parseInt(id));

  if (!loanDetail) {
    return <div className="text-center">Loan details not found.</div>; // Fallback for invalid ID
  }

  const handleNavigate = () => {
    const loanType = loanDetail.id; // For example, "loanDEt"
    
    // Navigate to loan-calculator page with the loanType query param
    navigate(`/loan-calculator?loanType=${loanType}`);
  };

  return (
    <div className="item item-1 details">
      <Header />
      <div className="more-info about-info m-0 p-0">
        <div className="w-100">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <div className="rounded p-3">
                <div>
                  <h1 className="text-center text-dark right-content">{loanDetail.title}</h1>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <LazyLoadImage
                      src={loanDetail.image} // Use image from JSON
                      alt="Loan Image"
                      style={{ width: "4cm", marginLeft: "15px" }}
                      effect="blur"
                    />
                    <p style={{ marginLeft: "15px" }}>{loanDetail.summary}</p>
                  </div>
                </div>

                <div className="card border-0 text-dark" style={{ backgroundColor: "transparent" }}>
                  <div className="card-body">
                    <h5 className="card-title">Loan Details</h5>
                    <p>{loanDetail.description}</p>
                    <p>{loanDetail.more}</p>
                    <div className="loandetails-btn">
                    <button
                      className="btn btn-loan-btn-1  mt-2 text-dark "
                      // onClick={() => navigate("/loan-calculator")}
                      onClick={handleNavigate}

                    >
                      Loan Calculator
                    </button>
                    <button
                   
                      className="btn btn-loan-btn-2  mt-2 text-dark "
                      onClick={() => user?navigate(`/profile?loan_id=${id}&tab=3`):navigate("/auth")}
                    >
                      Apply Now
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 p-5 col-sm-12">
              <div className="container">
                <div className="row justify-content-space-between">
                  <div className="col-md-12 mb-4">
                    <h5>Requirements</h5>
                    <hr className="bg-success" />
                    <ul className="list-unstyled">
                      {loanDetail.requirements.map((req, idx) => (
                        <li key={idx}>✓ {req}</li>
                      ))}
                    </ul>
                    <p>
                      <strong>Fees:</strong> {loanDetail.fees}
                    </p>
                    <div className="card service-card shadow">
                      <div className="card-body">
                        <h2 className="card-title text-success">
                          {loanDetail.title}
                          <span className="ml-5">
                            {loanDetail.type === "Businesses" ? (
                              <Briefcase size={29} className="justify-content-end" color="orange" />
                            ) : (
                              <Person size={29} className="justify-content-end" color="black" />
                            )}
                          </span>
                        </h2>
                        <p className="p-0 mb-3 text-start text-dark" style={{ lineHeight: "1.2" }}>
                          {loanDetail.summary}
                        </p>
                      </div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Interest Rates</th>
                            <th>Maximum Duration (Months)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <ul>
                                {Object.entries(loanDetail.interestRates).map(
                                  ([duration, rate]) => (
                                    <li key={duration}>
                                      {duration+` Months`}: {rate}
                                    </li>
                                  )
                                )}
                              </ul>
                            </td>
                            <td>{loanDetail.maximumDuration}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanDetails;
