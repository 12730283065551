import propTypes from "prop-types";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

//import LoansInputsHeader from "../../../../layouts/profile/components/loans";

import LoanDetails from "./LoanDetails";
import { useState } from "react";
import { useLocation } from "react-router-dom";



function LoansInput({onSaveInputs }) {
  const [tabValue, setTabValue] = useState(0);
  const [savingInputs, setSavingInputs] = useState(false);

  const handleSaveInputs = () => {
    setSavingInputs(true);
    onSaveInputs(formData)
      .then(() => {
        setSavingInputs(false);
      })
      .catch((error) => {
        console.error("Error saving profile data:", error);
        setSavingInputs(false);
      });
  };

  const handleTabChange = (newValue) => {
    console.log(" tab value obtaines now", newValue);
    setTabValue(newValue);
  };

  
  const renderLoanTabs = () => {
    switch (tabValue) {
            case 0:
              return (
                <LoanDetails
                 handleSaveInputs={handleSaveInputs}
                 savingInputs={savingInputs}
                />
              );
      case 1:
        return (<></>);
      default:
        return null;
    }
  };
  return (
    <CardContent>
      <Grid container spacing={2}>
        {/* Conditionally render additional fields if holder_type is "company" */}
        {/* <LoansInputsHeader
          handleTabChange={handleTabChange}
          tabValue={tabValue}
          tabsOrientation={"horizontal"}
          setTabsOrientation={() => {}}
        /> */}
        {/* {renderLoanTabs()} */}
        <LoanDetails/>

      </Grid>

      {/* You can add more conditions for additional steps if needed */}
    </CardContent>
  );
}

LoansInput.propTypes = {
  onSaveInputs: propTypes.func.isRequired,
};

export default LoansInput;
