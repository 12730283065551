// Styled component for Slider
import { styled, alpha } from "@mui/system";
import colors from "../../assets/theme/base/colors";
import Slider, { sliderClasses } from "@mui/material/Slider";

const { blue,grey } = colors;

export const LayoutSliderRoot = styled(Slider)(
    ({ theme }) => `
    color: ${theme.palette.mode === 'light' ? blue[500] : blue[400]};
    height: 6px;
    width: 100%;
    padding: 16px 0;
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
  
    &.${sliderClasses.disabled} {
      pointer-events: none;
      cursor: default;
      color: ${theme.palette.mode === 'light' ? grey[300] : grey[600]};
      opacity: 0.4;
    }
  
    & .${sliderClasses.rail} {
      display: block;
      position: absolute;
      width: 100%;
      height: 4px;
      border-radius: 6px;
      background-color: currentColor;
      opacity: 0.3;
    }
  
    // & .${sliderClasses.track} {
    //   display: block;
    //   position: absolute;
    //   height: 4px;
    //   border-radius: 6px;
    //   background-color: currentColor;
    // }
  
    & .${sliderClasses.thumb} {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      margin-left: -6px;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border-radius: 50%;
      outline: 0;
      background-color: ${theme.palette.mode === 'light' ? blue[500] : blue[400]};
      transition-property: box-shadow, transform;
      transition-timing-function: ease;
      transition-duration: 120ms;
      transform-origin: center;
  
      &:hover {
        box-shadow: 0 0 0 6px ${alpha(
          theme.palette.mode === 'light' ? blue[200] : blue[300],
          0.3,
        )};
      }
  
      &.${sliderClasses.focusVisible} {
        box-shadow: 0 0 0 8px ${alpha(
          theme.palette.mode === 'light' ? blue[200] : blue[400],
          0.5,
        )};
        outline: none;
      }
  
      &.${sliderClasses.active} {
        box-shadow: 0 0 0 8px ${alpha(
          theme.palette.mode === 'light' ? blue[200] : blue[400],
          0.5,
        )};
        outline: none;
        transform: scale(1.2);
      }
    }
  
      & .label {
        font-family: IBM Plex Sans;
        font-weight: 600;
        font-size: 14px;
        background: unset;
        background-color: ${theme.palette.mode === 'light' ? blue[600] : blue[900]};
        width: 32px;
        height: 32px;
        padding: 0px;
        visibility: hidden;
        color: #fff;
        border-radius: 50% 50% 50% 0;
        position: absolute;
        transform: translate(0%, -140%) rotate(-45deg) scale(0);
        transition: transform 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      :hover .label {
        visibility: visible;
        transform: translate(0%, -140%) rotate(-45deg) scale(1);
      }
  
      :hover .value {
        transform: rotate(45deg);
        text-align: center;
      }
    }
  `,
  );