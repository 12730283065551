import { forwardRef } from "react";
import PropTypes from "prop-types";
import { useLayoutController } from "../../context";
import LayoutInputRoot from "./LayoutInputRoot";
import CustomSelect from "./LayoutInputSelectRoot";

const LayoutInput = forwardRef(
  (
    { size, error, success, disabled, name, select, options, label, selectedValue, handleChange, type, helperText,required, ...rest },
    ref
  ) => {
    const [controller] = useLayoutController();
    const { darkMode } = controller;
    const labelWithOptional = label ? `${label}${!required ? " (Optional)" : ""}` : "";

    // Render Select component if select prop is true
    if (select) {
      return (
        <CustomSelect
          value={selectedValue}
          name={name}
          handleChange={handleChange}
          options={options}
          label={label}
          ownerState={{ size, error, success, disabled, darkMode, asSelect: true }}
        />
      );
    }

    // Render Input component
    return (
      <div>
        <LayoutInputRoot
          name={name}
          type={type}
          {...rest}
          ref={ref}
          ownerState={{ size, error, success, disabled, darkMode, asSelect: false }}
        />
        {error && helperText && (
          <div style={{ color: "red", fontSize: "12px", marginTop: "4px" }}>
            {helperText}
          </div>
        )}
      </div>
    );
  }
);

LayoutInput.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  disabled: false,
  select: false,
  options: [],
  type: "text",
  helperText: "", // Default value for helperText
  required: true,  // Default value for required is true
};

LayoutInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  selectedValue: PropTypes.string,
  handleChange: PropTypes.func,
  select: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  helperText: PropTypes.string, // Add prop type for helperText
  required: PropTypes.bool, // Add prop type for required
};

export default LayoutInput;
