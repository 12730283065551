import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { loginUser } from "../../store/users.store";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const [visiblePassword, setVisiblePassword]=useState(false);

  //state variable to show password visibility state
  const [showPassword, setShowPassword] = useState(false);
  const loading = useSelector((state) => state.users.loading);
  console.log("loading", loading)

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: '100vh',
    marginBottom: "3cm",
  };

  const formStyle = {
    padding: "20px",
    background: "white",
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    textAlign: "left",
  };

  const formGroupStyle = {
    marginBottom: "20px",
    textAlign: "left",
  };

  // const toggleVisiblePassword=()=>{
  //   setVisiblePassword(!visiblePassword)
  // }

  //function to toggle password visibility state
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    dispatch(loginUser(values)).then((result) => {
      if (result?.payload?.success === true) {
        const statusCompletion = result?.payload?.status_completion;

        // Check if all statuses are "completed"
        const isProfileComplete = statusCompletion?.profile_status?.status === "completed";
        const isKinComplete = statusCompletion?.kin_status?.status === "completed";
        const isUploadComplete = statusCompletion?.upload_status?.status === "completed";

        if (isProfileComplete && isKinComplete && isUploadComplete) {
          // All statuses are completed, navigate to services
          navigate("/services");
        } else {
          // If any status is incomplete, navigate to profile
          navigate("/profile");
        }
      }

      setSubmitting(false);
    });
  };

  return (
    <div className="item item-13 container-fluid">
      <div className="img-fill row ">
        <nav className="navbar navbar-expand-lg" style={{ position: "relative", zIndex: "1000" }}>
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src="./assets/images/logo.png" alt="quick sava logo" style={{ width: "8cm" }} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="col-md-6 col-sm-12 service_heading">
          <div style={containerStyle}>
            <div className="auth service_heading service-size" style={formStyle}>
              <h1>
                <strong style={{ fontFamily: "trueno" }}>Login</strong>
              </h1>
              <p className="text-dark">
                Don&apos;t have an account yet?{" "}
                <Link to="/register" style={{ color: "#ff8b00" }}>
                  <small>Sign up</small>
                </Link>
              </p>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={handleFormSubmit}
              >
                <Form>
                  <div style={formGroupStyle}>
                    <label htmlFor="exampleInputEmail1 mt-0">Email address</label>
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="you@example.com"
                    />
                  </div>
                  <div style={formGroupStyle}>
                    {/* <label htmlFor="exampleInputPassword1">Password <a href="/verify" style={{color:"#ff8b00"}}>Forgot password?</a></label> */}
                    <label htmlFor="exampleInputPassword1">
                      Password &nbsp; &nbsp; &nbsp;
                      <Link
                        to="/verify"
                        style={{ color: "#ff8b00", textAlign: "end" }}
                        className="text-end"
                      >
                        <small>Forgot password?</small>
                      </Link>
                    </label>

                    <div className="d-flex controlled-input-component align-items-center">
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className="form-control controlled-input-password"
                        id="exampleInputPassword1"
                        placeholder="Enter 6 character or more"
                      />

                      <span onClick={toggleShowPassword} className="px-2">
                        {showPassword ? (
                          <FontAwesomeIcon
                            icon={faEye}
                            style={{ color: "#050505", fontSize: "20px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            style={{ color: "#050505", fontSize: "20px" }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label mb-4" htmlFor="exampleCheck1">
                      Remember me{" "}
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="btn  w-100 text-light"
                    // disabled={loading}
                    style={{ borderRadius: "10px", backgroundColor: "#ff8b00", fontSize: ".6cm" }}
                  >
                    Login
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer style={{ zIndex: "1", position: "absolute", top: "100px" }} />
    </div>
  );
};

export default Login;
