import { forwardRef } from "react";
import { AppBar, Card, Container, Grid, Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";

import LoansInput from "../../../../widgets/Cards/InfoCards/LoanInputs";

const PlatformLoans = forwardRef((props, ref) => {
  const userData = useSelector((state) => state?.users?.user);

  const handleSaveCompany = (profileData) => {
    // Placeholder function for onSaveCompany
    return new Promise((resolve, reject) => {
      // Replace this with your actual API call to save profile data
      setTimeout(() => {
        console.log("Profile data saved:", profileData);
        resolve();
      }, 1000);
    });
  };

  return (
    <Card>
      <Container sx={{ mt: 3, mb: 3 }} maxWidth="xl">
        <Grid container spacing={4}>
          {/* Profile card */}

          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
            </AppBar>
          </Grid>
          <Grid item xl={12} xs={12}>
            <LoansInput userData={userData} onSaveCompany={handleSaveCompany} />
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
});

export default PlatformLoans;
