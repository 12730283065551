import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFromLocalStorage } from "../utils/local-storage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { calculateLoan, uploadDocuments, newLead } from "../store/loan.store";
import Header from "./Header";
import Slider from "@mui/material/Slider";
import Footer from "./Footer";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import LayoutSlider from "./LayoutSlider";
import LoanSlider from "./LayoutSlider/LoanSlider";

import loanDetails from "../assets/data/loans.json"; // Import loan details

const LoanCalculator = () => {
  const dispatch = useDispatch();
  const user = getFromLocalStorage("user");
  const navigate = useNavigate();
  const [loanResult, setLoanResult] = useState([]);
  const [rangeValue, setRangeValue] = useState(20000000);
  const [loanInstallmentAmount, setLoanInstallmentAmount] = useState(0);
  const location = useLocation();

  const inputRef = useRef(null);

  const loading = useSelector((state) => state.loans.loading);

  console.log("Are we loading ", loading);

  const [id_back, setIdBack] = useState(null);
  const [id_front, setIdFront] = useState(null);
  const [mpesa_statement, setMpesaStatement] = useState(null);
  const [bank_statement, setBankStatement] = useState(null);
  const [kra_pin, setKraPin] = useState(null);
  const [period_in_months, setPeriodInMonths] = useState(null);
  const [amount_requested, setAmountRequested] = useState(null);
  const [till_paybill, setPaybillTill] = useState(null);
  const queryParams = new URLSearchParams(location.search); // Get query params from URL
  const loanTypeFromQuery = queryParams.get("loanType"); // Extract loanType query param

  const loanDetail = loanDetails.find((loan) => loan.id === parseInt(loanTypeFromQuery??1));
  // const [loanType, setLoanType] = useState("logbook_loan");

  const { interestRates, maximumDuration } = loanDetail || {};
  const [installments, setInstallmentsValue] = useState(maximumDuration);

  const [maxDuration, setMaxDuration] = useState(24);
  const [error, setError] = useState("");

  // const handleLoanType = (e) => {
  //   setLoanType(e.target.value);
  //   if (e.target.value === "asset_finance") {
  //     setMaxDuration(48);
  //   } else if (e.target.value !== "asset_finance") {
  //     setMaxDuration(24);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("requested_amount", amount_requested);
    formData.append("payment_period", period_in_months);
    formData.append("kra_pin", kra_pin);
    formData.append("mpesa_statement", mpesa_statement); // Use the first file from the array
    formData.append("bank_statement", bank_statement); // Use the first file from the array
    formData.append("id_front", id_front); // Use the first file from the array
    formData.append("id_back", id_back);
    formData.append("user_id", user?.id);
    formData.append("till_paybill", till_paybill);

    dispatch(uploadDocuments(formData)).then((result) => {
      if (result?.payload?.success === true) {
      }
      console.log(result);
    });
  };

  useEffect(() => {
    if (loanDetail) {
      setMaxDuration(loanDetail.maximumDuration);
    }
  }, [loanDetail]);

  // function calculateMonthlyPayment(principal, months, loanType) {
  //   // Constants for fees and insurance
  //   const carTrackFee = 3000;
  //   const creditLifeInsuranceRate = 0.03; // 3% |terms and condirtions
  //   const applicationFee = 0.045; //maintain
  //   const rtgs = 1000;
  //   const carTrackInstallation = 5000;
  //   const vehicleTransferFee = 3675;
  //   const crb = 500; //maintain
  //   const legalFee = 1000;
  //   const membership = 1000; //maintain
  //   const chattelsFee = 3000;

  //   // Product details: maximum durations and monthly interest rates
  //   const products = {
  //     logbook_loan: {
  //       maximum_duration: 24,
  //       monthly_interest: {
  //         1: 10,
  //         "2-6": 5.5,
  //         "7-12": 5,
  //         "13-24": 3,
  //       },
  //     },
  //     import_loan: {
  //       maximum_duration: 24,
  //       monthly_interest: {
  //         1: 10,
  //         "2-6": 5.5,
  //         "7-12": 5,
  //         "13-24": 3,
  //       },
  //     },
  //     asset_finance: {
  //       maximum_duration: 48,
  //       monthly_interest: {
  //         1: 10,
  //         "2-6": 5.5,
  //         "7-12": 5,
  //         "13-48": 3,
  //       },
  //     },
  //   };

  //   // Get the monthly interest rate based on the loan type and duration
  //   let interest;
  //   if (months === 1) {
  //     interest = products[loanType]?.monthly_interest["1"];
  //   } else if (months >= 2 && months <= 6) {
  //     interest = products[loanType]?.monthly_interest["2-6"];
  //   } else if (months >= 7 && months <= 12) {
  //     interest = products[loanType]?.monthly_interest["7-12"];
  //   } else if (months >= 13 && months <= 48) {
  //     interest = products[loanType]?.monthly_interest["13-24"];
  //   }
  //   interest = parseFloat(interest || 0); // Default to 0 if interest is undefined

  //   // Convert input values to numbers
  //   principal = parseFloat(principal);
  //   months = parseFloat(months);

  //   // Calculate monthly payment
  //   let monthlyPayment = principal * (interest / 100); // Monthly interest
  //   // monthlyPayment += principal * creditLifeInsuranceRate; // Insurance
  //   // monthlyPayment += (carTrackFee * months)+chattelsFee+rtgs+crb+membership+vehicleTransferFee+legalFee+carTrackInstallation+(principal*applicationFee); // One-time fee
  //   monthlyPayment = (principal + monthlyPayment * months) / months; // Total monthly payment

  //   return monthlyPayment.toFixed(2); // Return the result with two decimal places
  // }

  const calculateMonthlyPayment = (principal, months) => {
    if (!loanDetail) return 0;

    // console.log("all intrest rates", interestRates)

    // Get the monthly interest rate based on the loan type and duration
    let interestRate = 0;
    if (months === 1) {
      interestRate = interestRates["1"];
    } else if (months >= 2 && months <= 6) {
      interestRate = interestRates["2-6"];
    } else if (months >= 7 && months <= 12) {
      interestRate = interestRates["7-12"];
    } else if (months >= 13 && months <= 48) {
      interestRate = interestRates["13-24"];
    }
    // console.log(" intrest rates", interestRate)

    interestRate = parseFloat(interestRate || 0); // Default to 0 if interest is undefined
    principal = parseFloat(principal);
    months = parseFloat(months);

    // Calculate monthly payment
    let monthlyPayment = principal * (interestRate / 100); // Monthly interest
    monthlyPayment = (principal + monthlyPayment * months) / months; // Total monthly payment

    return monthlyPayment.toFixed(2);
  };

  const handleRangeChange = (event) => {
    setRangeValue(event.target.value);
    // Use the updated value of rangeValue after setRangeValue has been processed
    // setLoanInstallmentAmount((prevLoanInstallmentAmount) => {
    //   var monthlyPayment = calculateMonthlyPayment(event.target.value, installments, loanType);
    //   return monthlyPayment;
    // });
  };

  // const handleInstallmentsChange = (event) => {
  //   setInstallmentsValue(event.target.value);
  //   // Use the updated value of rangeValue after setRangeValue has been processed
  //   // setLoanInstallmentAmount((prevLoanInstallmentAmount) => {
  //   //   var monthlyPayment = calculateMonthlyPayment(rangeValue, event.target.value, loanType);
  //   //   return monthlyPayment;
  //   // });
  // };

  // const handleInstallmentsChange = (event) => {
  //   const newInstallmentsValue = event.target.value;

  //   // Check if the new installments value exceeds maximumDuration
  //   if (newInstallmentsValue > maximumDuration) {
  //     // Optionally, display an error or handle it in some way
  //     console.log(`Installments cannot exceed ${maximumDuration}`);
  //     return; // Do not update the value if it exceeds the maximum duration
  //   }

  //   setInstallmentsValue(newInstallmentsValue);

  //   // Optionally, you can also calculate and update the loan installment amount
  //   setLoanInstallmentAmount((prevLoanInstallmentAmount) => {
  //     var monthlyPayment = calculateMonthlyPayment(rangeValue, newInstallmentsValue, loanType);
  //     return monthlyPayment;
  //   });
  // };

  const handleInstallmentsChange = (event) => {
    const newInstallmentsValue = event.target.value;

    // Check if the new installments value exceeds maximumDuration
    if (newInstallmentsValue > maximumDuration) {
      // Set the error message
      setError(`Installments cannot exceed ${maximumDuration}`);
      return; // Do not update the value if it exceeds the maximum duration
    }

    // Clear error if value is within range
    setError("");

    // Update installments value and calculate the monthly payment
    setInstallmentsValue(newInstallmentsValue);

    // Optionally, calculate the loan installment amount
    setLoanInstallmentAmount((prevLoanInstallmentAmount) => {
      var monthlyPayment = calculateMonthlyPayment(rangeValue, newInstallmentsValue);
      return monthlyPayment;
    });
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "KES",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const handleNewLead = (values) => {
    dispatch(newLead(values)).then((result) => {
      if (result?.payload?.success === true) {
        navigate("/register");
      }
    });
  };

  useEffect(() => {
    console.log("we are doing calculations here");
    const monthlyPayment = calculateMonthlyPayment(rangeValue, installments);
    setLoanInstallmentAmount(monthlyPayment);
  }, [rangeValue, installments, loanDetail]);

  return (
    <div>
      <div className="item item-9 h-100">
        <Header />
        <div>
          <h4
            style={{ letterSpacing: "-0.03em", font: "trueno", zIndex: 1000 }}
            className="mb-0 mt-0 my-3 d-flex justify-content-center align-items-center text-center"
          >
            LOAN CALCULATOR
          </h4>
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              fontSize: ".6cm",
              letterSpacing: "0.05em",
              fontWeight: "normal",
              marginTop: "1cm",
              display:"flex",
              flexWrap:"wrap"
            }}
            className="mb-0"
          >
            The calculated amount is an approximate for:&nbsp;<b>{loanDetail?.title}</b>. The exact terms are determined individually
          </h3>
        </div>
        <div className="img-fill calculator-1 pb-0">
          <div
            className={`row ${loading ? "" : "d-none"}`}
            style={{
              position: "absolute",
              top: ".2cm",
              left: "25%",
              zIndex: "1000",
              background: "rgba(12, 10, 10, 0.5)",
            }}
          >
            <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
              <h6 className="text-dark d-block col-12 text-muted">Uploading documents....</h6>
              <p className="d-block col-12">
                <CircularProgress color="info" className="d-block" />
              </p>
            </Stack>
          </div>
          <div className="">
            {/* <div className={`service_heading ${!user ? "" : "d-none"}`}> */}

            <div className={`service_heading `}>
              <div className="">
                <div className="row justify-content-between py-4">
                  <div className="col-md-6  col-sm-12">
                    <form className="form-group user_form">
                      <div className="" style={{ display: "grid" }}>
                        <label
                          htmlFor="rangeInput"
                          style={{
                            fontSize: "1.2em",
                            fontWeight: "bold",
                          }}
                        >
                          How much do you need?{" "}
                          <span
                            style={{ color: "#a4c639" }}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <h5 className="text-muted">
                              <div className="d-flex flex-column w-auto justify-content-center align-item-center">
                                <input
                                  type="number"
                                  name="rangeInput"
                                  value={rangeValue}
                                  style={{ width: "10cm", padding: "15px", borderRadius: "21px" }}
                                  onChange={handleRangeChange}
                                />
                                <div className="w-100 d-flex justify-content-center align-items-center">
                                  <LayoutSlider
                                    size="small "
                                    defaultValue={50000}
                                    valueLabelDisplay="auto"
                                    className="me-5 "
                                    step={1000}
                                    max={5000000}
                                    min={50000}
                                    value={rangeValue}
                                    onChange={handleRangeChange}
                                    style={{ color: "#00bfba", marginTop: "-20px", width: "80%" }}
                                  />
                                </div>
                              </div>
                            </h5>
                          </span>
                        </label>

                        <label
                          htmlFor="rangeInput"
                          className=""
                          style={{
                            fontSize: "1.2em",
                            fontWeight: "bold",
                          }}
                        >
                          How many months do you need?{" "}
                          <span style={{ color: "#a4c639" }}>
                            <h5 className="text-muted">
                              <input
                                type="number"
                                name="installmmentsInput"
                                onChange={handleInstallmentsChange}
                                value={installments}
                                style={{ width: "10cm", padding: "15px", borderRadius: "21px" }}
                              />
                              {error && <div style={{ color: "red" }}>{error}</div>}{" "}
                              {/* Display error if exists */}
                            </h5>
                          </span>
                        </label>

                        <div
                          className=" py-4 d-flex flex-column align-items-center rounded card-record"
                          style={{
                            backgroundColor: "#e9e8e8",

                            marginTop: "34px",
                          }}
                        >
                          <label
                            style={{
                              fontSize: "35px",
                              alignItems: "center",
                              borderRadius: "10px",
                            }}
                          >
                            You will pay :{" "}
                            <span style={{ color: "#00BFBA" }}>
                              <h5 className="">
                                <span
                                  className="btn pay-record "
                                  style={{
                                    width: "10rem",
                                    alignContent: "center",
                                  }}
                                >
                                  <strong
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {formatter.format(loanInstallmentAmount)}{" "}
                                  </strong>
                                </span>
                              </h5>
                            </span>
                            Per Month
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="col-md-6">
                    <LoanSlider />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoanCalculator;
