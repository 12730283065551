import "./App.css";
import React, { useEffect, useCallback, Suspense } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

import LoanCalculator from "./components/LoanCalculator";

// import Overview from "./layouts/profile/Index";
import { LayoutControllerProvider } from "./context";
import Profile from "./layouts";
import PerfectScrollbar from "react-perfect-scrollbar";
import HomeLayout from "./components/homeLayout";

function App() {


  return (
    <div className="App w-100 h-100">
      <BrowserRouter>
        <Suspense fallback={<p> Loading ... </p>}>
          <Routes>
            <Route path={"/loan-calculator"} element={<LoanCalculator />}></Route>
          </Routes>
          <LayoutControllerProvider>
            {/* <PerfectScrollbar> */}
              <Profile />
            {/* </PerfectScrollbar> */}
            {/* <HomeLayout /> */}

          </LayoutControllerProvider>
          <LayoutControllerProvider>
            <HomeLayout />
          </LayoutControllerProvider>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
