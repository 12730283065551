import React from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { CircularProgress, Grid } from "@mui/material";
import LayoutTypography from "../../../../components/LayoutTypography";
import LayoutInput from "../../../../components/LayoutInput";
import LayoutButton from "../../../../components/LayoutButton";
import LayoutBox from "../../../../components/LayoutBox";
import { LayoutToolTip } from "../../../../components/LayoutToolTip";
import LayoutFileInput from "../../../../components/LayoutInput/LayoutFileInput";
import PropTypes from "prop-types";
import { companyFormFields, directorFields } from "./data";
import companyValidationSchema from "./limitedLiabilityValidation";

function LimitedLiabilityCompany({ handleSaveCompany, savingCompany }) {
  const initialValues = {
    company_directors: [{}], // At least one director required
    ...Object.fromEntries(companyFormFields.map((field) => [field.name, ""])),
  };

console.log("limited liability validation schema", companyValidationSchema)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={companyValidationSchema}
      onSubmit={(values) => {
        console.log("values added here", values)
        handleSaveCompany(values);
      }}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LayoutTypography
                variant="h2"
                fontWeight="medium"
                textTransform="capitalize"
                textAlign="center"
              >
                Limited Liability Company
              </LayoutTypography>
            </Grid>

            {/* Company Form Fields */}
            {companyFormFields.map((field, index) => (
              <Grid item xs={12} sm={6} key={index}>
                {field.type === "text" ? (
                  <>
                    <Field
                      as={LayoutInput}
                      type="text"
                      multiline
                      rows={4}
                      name={field.name}
                      // placeholder={field.placeholder}
                      placeholder={
                        field.placeholder && !field.required
                          ? `${field.placeholder} (Optional)`
                          : field.placeholder
                      }
                      size="large"
                    />
                    <ErrorMessage
                      name={field.name}
                      component="div"
                      style={{ color: "red", fontSize: "10px" }} // Error Message Styling
                    />
                  </>
                ) : (
                  <>
                    <LayoutFileInput
                      type="file"
                      name={field.name}
                      handleChange={(event) => {
                        console.log("event data", event)
                        const name=event.target.name
                        const file = event.target.files[0]; // Add safeguard here
                        if (file) {
                          setFieldValue(name, file);
                        }
                      }}
                      placeholder={field.placeholder}
                      required={field.required}
                      size="large"
                    />
                    <ErrorMessage
                      name={field.name}
                      component="div"
                      style={{ color: "red", fontSize: "10px" }} // Error Message Styling
                    />
                  </>
                )}
              </Grid>
            ))}

            {/* Directors Section */}
            <Grid item xs={12}>
              <LayoutTypography
                variant="h6"
                fontWeight="small"
                textTransform="capitalize"
                textAlign="start"
              >
                Add Directors
              </LayoutTypography>

              <FieldArray name="company_directors">
                {({ push, remove }) => (
                  <>
                    {values.company_directors.map((_, index) => (
                      <LayoutBox key={index} mb={3}>
                        <Grid container spacing={2}>
                          {directorFields.map((field, dirIndex) => (
                            <Grid item xs={12} sm={6} key={dirIndex}>
                              <Field
                                as={LayoutInput}
                                type="text"
                                name={`company_directors.${index}.${field.name}`}
                                placeholder={field.placeholder}
                                size="large"
                              />
                              <ErrorMessage
                                name={`company_directors.${index}.${field.name}`}
                                component="div"
                                style={{ color: "red", fontSize: "10px" }} // Error Message Styling
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </LayoutBox>
                    ))}

                    {/* Add/Remove Director Buttons */}
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                        <LayoutToolTip title="Add Directors" color="black" background="danger">
                          <LayoutButton onClick={() => push({})} variant="contained" color="info">
                            Add Director
                          </LayoutButton>
                        </LayoutToolTip>
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <LayoutToolTip
                          title={
                            values.company_directors.length === 1
                              ? "At least one director is needed"
                              : "Remove Director"
                          }
                          color="black"
                          background="red"
                        >
                          <LayoutButton
                            variant={values.company_directors.length === 1 ? "span" : "contained"}
                            onClick={() =>
                              values.company_directors.length > 1 &&
                              remove(values.company_directors.length - 1)
                            }
                            color={values.company_directors.length === 1 ? "danger" : "warning"}
                            disabled={values.company_directors.length === 1}
                          >
                            Remove Director Field
                          </LayoutButton>
                        </LayoutToolTip>
                      </Grid>
                    </Grid>
                  </>
                )}
              </FieldArray>
            </Grid>

            {/* Save Button */}
            <Grid item xs={5}>
              <LayoutButton
                type="submit"
                variant="contained"
                color="info"
                disabled={isSubmitting || savingCompany}
              >
                {savingCompany || isSubmitting ? <CircularProgress size={24} /> : "Save"}
              </LayoutButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

LimitedLiabilityCompany.propTypes = {
  savingCompany: PropTypes.bool.isRequired,
  handleSaveCompany: PropTypes.func.isRequired,
};

export default LimitedLiabilityCompany;
