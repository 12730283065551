import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Layout Dashboard 2 MUI components
import LayoutBox from "../../../../components/LayoutBox";
import LayoutTypography from "../../../../components/LayoutTypography";
import LayoutAvatar from "../../../../components/LayoutAvatar";
import PropTypes from "prop-types";

// Layout Dashboard 2 MUI example components
import DashboardNavbar from "../../../../widgets/Navbars/DashboardNavbar";

// Layout Dashboard 2 MUI base styles
import breakpoints from "../../../../assets/theme/base/breakpoints";
// Images
import burceMars from "../../../../assets/images/bruce-mars.jpg";
import { useSelector } from "react-redux";
import { Display } from "react-bootstrap-icons";


function Header({ handleTabChange, tabValue, tabsOrientation, setTabsOrientation }) {
  // const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  // const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    handleTabChange(newValue);
  };
  const userData = useSelector((state) => state?.users?.user);

 


  return (
    <LayoutBox position="relative">
      <DashboardNavbar absolute light />
      <LayoutBox height="220px" />
      <Card
        sx={{
          py: 2,
          px: 2,
          boxShadow: ({ boxShadows: { md } }) => md,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <LayoutAvatar
              src={burceMars}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <LayoutBox height="100%" mt={0.5} lineHeight={1}>
              <LayoutTypography variant="h5" fontWeight="medium">
                {userData?.name}
              </LayoutTypography>
              <LayoutTypography variant="button" color="text" fontWeight="medium">
                {userData?.customer_profile?.address?? "N/A"}
              </LayoutTypography>
            </LayoutBox>
          </Grid>
          <Grid item xs={12} md={7} lg={7} sx={{ ml: "auto" }}>
            <AppBar position="static">
              {/* {console.log("tabvalues here", tabValue)} */}
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
              <Tab
                  label="Profile Status"
                  icon={
                    <i
                      className="ni ni-single-02"
                      style={{ marginTop: "6px", marginRight: "8px" }}
                    />
                  }
                />
                <Tab
                  label="Profile"
                  className={"d-none"}

                  icon={
                    <i
                      className="ni ni-circle-08"
                      style={{ marginTop: "6px", marginRight: "8px" }}
                    />
                  }
                />
                 <Tab
                  label="My Business"
                  
                  icon={
                    <i
                      className="ni ni-diamond"
                      style={{ marginTop: "6px", marginRight: "8px" }}
                    />
                  }
                />
                 <Tab
                  label="Loan Application"
                  
                  
                />
               
                <Tab
                  label="App"
                  icon={
                    <i className="ni ni-app" style={{ marginTop: "6px", marginRight: "8px" }} />
                  }
                />
                {/* <Tab
                  label="Message"
                  icon={
                    <i
                      className="ni ni-email-83"
                      style={{ marginTop: "6px", marginRight: "8px" }}
                    />
                  }
                /> */}
                <Tab
                  label="Settings"
                  icon={
                    <i
                      className="ni ni-settings-gear-65"
                      style={{ marginTop: "6px", marginRight: "8px" }}
                    />
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </LayoutBox>
  );
}

Header.propTypes = {
  handleTabChange: PropTypes.func.isRequired,
  setTabsOrientation: PropTypes.func.isRequired,
  tabValue: PropTypes.number.isRequired,
  tabsOrientation: PropTypes.string.isRequired,
};

export default Header;
