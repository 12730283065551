import Header from "./Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../utils/local-storage";
import Footer from "./Footer";
import VisionMissionValues from "./coreDescription";

const About = () => {
  const user = getFromLocalStorage("user");
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "#fff" }} className="">
      <div className="item item-7 hero-banner-about">
        <Header />
        <div className="img-fill ">
          <section className="" id="">
            <div className="container ">
              <div className="row ">
                <div className="col-md-5 "></div>
                <div className="col-md-7" style={{ zIndex: "5" }}>
                  <div className="section-heading service_heading text-dark">
                    <h4
                      style={{ fontSize: "3em", letterSpacing: "-0.03em", font: "trueno" }}
                      className="mb-0 mt-0 my-3"
                    >
                      ABOUT QUICKSAVA
                    </h4>

                    <h3
                      style={{
                        fontSize: ".6cm",
                        letterSpacing: "0.05em",
                        fontWeight: "normal",
                        marginTop: "1cm",
                      }}
                      className="mb-0"
                    >
                      Welcome to QuickSava, where your business growth is our priority. We are a
                      leading microfinance institution dedicated to empowering individuals and SMEs
                      with fast, flexible, and reliable financial solutions.
                      <br /> At QuickSava, we understand the challenges that come with running and
                      expanding a business, which is why we have tailored our loan services to meet
                      your unique needs. <br />
                      At QuickSava, we believe in the power of small businesses and entrepreneurs to
                      shape the future. Let us be the financial partner that helps you turn your
                      vision into reality.
                      <br />
                      Join us, and let’s grow together.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="row justify-content-end mt-5" style={{zIndex:'7', position:'relative'}}>
          <VisionMissionValues />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;
