import { Link } from "react-router-dom";
import LayoutBadge from "../../../components/LayoutBadge";
import LayoutBox from "../../../components/LayoutBox";
import LayoutButton from "../../../components/LayoutButton";
import LayoutTypography from "../../../components/LayoutTypography";
import { Tooltip } from "@mui/material";
import { LayoutToolTip } from "../../../components/LayoutToolTip";

const ProfileSettingsData = [
  {
    color: "info",
    icon: <i className="ni ni-mobile-button" style={{ fontSize: "10px" }} />,
    name: "profile_status",  // Match the name to your localStorage keys
    label: "Profile Info",
    description: "",
    completionStatus: "Incomplete", // default value, to be updated later
    route: "",
    onCategoryClick: 0,
  },
  {
    color: "info",
    icon: <i className="ni ni-box-2" style={{ fontSize: "12px" }} />,
    name: "kin_status",  // Match the name to your localStorage keys
    label: "Next Of Kin",
    description: "",
    completionStatus: "Complete", // default value, to be updated later
    route: "",
  },
  {
    color: "info",
    icon: <i className="ni ni-tag" style={{ fontSize: "12px" }} />,
    name: "upload_status",  // Match the name to your localStorage keys
    label: "Upload",
    description: "",
    completionStatus: "Incomplete", // default value, to be updated later
    route: "",
  },
];

export default ProfileSettingsData;
