
import {  useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

// Layout Dashboard 2 MUI base styles
import breakpoints from "../../../../assets/theme/base/breakpoints";


function CompanyHeader({ handleTabChange, tabValue, tabsOrientation, setTabsOrientation }) {
  
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => handleTabChange(newValue);

  return (
   
    <Grid container spacing={4} alignItems="center">
      
     
      <Grid item xs={12} md={8} lg={6} sx={{ mr: "auto" }}>
        <AppBar position="static">
          <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
          <Tab
              label="LIMITED COMPANY"
              
            />
            <Tab
              label="BUSINESSES"
              
            />
            
          </Tabs>
        </AppBar>
      </Grid>
    </Grid>
  );
}
CompanyHeader.propTypes = {
  handleTabChange: PropTypes.func.isRequired,
  setTabsOrientation: PropTypes.func.isRequired,
  tabValue: PropTypes.number.isRequired,
  tabsOrientation: PropTypes.string.isRequired,
};

export default CompanyHeader;
