import { Link, useLocation, useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../utils/local-storage";

// eslint-disable-next-line
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';


const Header = () => {
  const user = getFromLocalStorage("user");
  const location = useLocation();
  const navigate = useNavigate();

  return (

    <nav className="navbar navbar-expand-md " style={{ position: "relative", zIndex: "1000" }}>
      <Link className="navbar-brand" to="/">
        <img
          src="./assets/images/logo.png"
          alt="quick sava logo"
          style={{ width: "5cm" }}
        />
      </Link>
      <div className="container main-header-size">

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto">
            <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
              <Link className="nav-link" to="/">Home
                <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className={`nav-item ${location.pathname === '/services' ? 'active' : ''}`}>
              <Link to="/services" className="nav-link" >Products</Link>
            </li>
            <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
              <Link className="nav-link" to="/about">About us</Link>
            </li>
            <li className={`nav-item ${location.pathname === '/career' ? 'active' : ''}`}>
              <Link className="nav-link" to="/career">Career</Link>
            </li>
            <li className={`nav-item ${location.pathname === '/loan-calculator' ? 'active' : ''}`}>
              <Link className="nav-link" to="/loan-calculator">Calculator</Link>
            </li>
            
            <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
              <Link to="/contact" className="nav-link" >Contact Us</Link>
            </li>


            {!user ? (
              <>
                <li className="nav-item">
                  <button style={{ width: "", borderRadius: "10px", backgroundColor: "#00bfba" }} className="filled-button btn text-light"
                    onClick={() => navigate("/auth")}>Login/Register</button>

                </li></>) : (
              <li className="nav-item">
                <Link className="nav-link " to="/dashboard"><FontAwesomeIcon icon={faUser} className="mx-2" />{user.name}</Link></li>
            )
            }

            <li
              className={`nav-item ${location.pathname === '/referal' ? 'active' : ''}`}>
              <Link to="/referal" className="nav-link refferal"
                style={{ backgroundColor: "#ff8b00", borderRadius: "10px" }}>
                Refer and Make Money
              </Link>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header;