import { CircularProgress, Grid } from "@mui/material";
import LoansInput from "../../../../assets/data/LoanInputs.json";
import LayoutButton from "../../../../components/LayoutButton";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import LayoutFileInput from "../../../../components/LayoutInput/LayoutFileInput";
import LayoutInput from "../../../../components/LayoutInput";

const LoanDetails = ({ handleSaveInputs, savingInputs }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const loan_id = parseInt(queryParams.get("loan_id")) || 1;

  const selectedLoan = LoansInput.find((loan) => loan.id === loan_id);

  // Dynamic Yup validation schema
  const createValidationSchema = (requirements) => {
    const schema = {};
    if (requirements?.length > 0) {
      requirements.forEach((field) => {
        schema[field.id] = field.required
          ? Yup.string().required(`${field.name} is required`)
          : Yup.string();
      });
    }
    return Yup.object().shape(schema);
  };

  const initialValues = selectedLoan?.requirements?.length
    ? Object.fromEntries(selectedLoan.requirements.map((req) => [req.id, ""]))
    : {};

  const [formData, setFormData] = useState(initialValues);

  useEffect(() => {
    console.log("Form Data:", formData);
  }, [formData]);

  const validationSchema = createValidationSchema(selectedLoan?.requirements);

  const handleSubmit = (values) => {
    console.log("Form Submitted:", values);
    handleSaveInputs(values);
  };


  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <h2>{selectedLoan?.title}</h2>
            <p>{selectedLoan?.summary}</p>
            <Grid container spacing={2}>
              {Array.isArray(selectedLoan?.requirements) ? (
                selectedLoan.requirements.map((req) => (
                  <Grid item xs={12} sm={6} key={req.id}>
                    {req.type === "text" ? (
                      <Field
                        name={req.id}
                        as={LayoutInput}
                        placeholder={req.placeholder || req.name}
                      />
                    ) : req.type === "file" ? (
                      <LayoutFileInput
                        name={req.id}
                        required={req.required}
                        placeholder={req.name}
                        handleChange={(event) =>
                          setFieldValue(req.id, event.target.files[0])
                        }
                      />
                    ) : null}
                    {errors[req.id] && touched[req.id] && (
                      <p style={{ color: "red" }}>{errors[req.id]}</p>
                    )}
                  </Grid>
                ))
              ) : (
                <p>No requirements found for this loan.</p>
              )}
            </Grid>

            <Grid item xs={5} className="mt-3">
              <LayoutButton
                variant="contained"
                color="info"
                type="submit"
                disabled={savingInputs}
              >
                {savingInputs ? <CircularProgress size={24} /> : "Save"}
              </LayoutButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

LoanDetails.propTypes = {
  savingInputs: PropTypes.bool.isRequired,
  handleSaveInputs: PropTypes.func.isRequired,
};

export default LoanDetails;
