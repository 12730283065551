import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { getFromLocalStorage } from "../utils/local-storage";
import { Formik, Form, Field } from "formik";
import { newLead } from "../store/loan.store";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelopeOpen, faGlobe, faStreetView } from "@fortawesome/free-solid-svg-icons";
import ContactMenuButton from "./LayoutButton/ContactMenuButton";
import { Padding } from "@mui/icons-material";
import { useState } from "react";




const Contact = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = getFromLocalStorage("user");
  const dispatch = useDispatch();

  const handleNewLead = (values) => {
    dispatch(newLead(values)).then((result) => {
      if (result?.payload?.success === true) {
        navigate("/register");
      }
    });
  };

  const [loanType, setLoanType] = useState("Product");
const handleLoanType = (e) => {
  setLoanType(e.target.value);
};

  function sendEmail(e) {
    e.preventDefault();

    const name = document.querySelector('input[name="name"]').value;
    const phone = document.querySelector('input[name="phone"]').value;
    const email = document.querySelector('input[name="email"]').value;
    const subject = document.querySelector('input[name="subject"]').value;
    const message = document.querySelector('textarea[name="message"]').value;
    const emailLink = `mailto:otitrevor450@gmail.com?subject=${subject}&body=Name: ${name}%0D%0APhone: ${phone}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    if (!name || !phone || !email || !message) {
      alert("Please fill all the fields");
      return;
    }

    window.open(emailLink);
  }

  return (
    <>
      <div className="item item-8">
        <Header />

        <div className="img-fill" style={{ height: "" }}>
          <div className="service_heading">
          <div className="col item-11">
              <h4
                style={{ fontSize: "3em", letterSpacing: "-0.03em", font: "trueno" , zIndex: 1000 }}
                className="mb-0 mt-0 my-3 d-flex justify-content-center align-items-center"
              >
                CONTACT  US
              </h4>
            </div>
            <div className="row">
              
            <div className="col-md-6 col-sm-12 col-lg-6">
              <div>
            <h3
              style={{
                fontSize: "1cm",
                letterSpacing: "0.05em",
                textAlign: "start",
                fontWeight: "bold",
                marginTop: "1cm",
              }}
              className="mb-0 mt-0 ml-5 mt-3 mb-3 px-2"
            >
              GET IN TOUCH
            </h3>
            </div>
            <div className="contact-info d-flex flex-column justify-content-start mt-5 align-items-start px-3 contact-details">
              <div className="d-flex gap-3   flex-column justify-content-start align-items-start px-5" style={{lineHeight:"1.9"}}>
                <span
                  style={{  cursor: "pointer" }}
                  onClick={() => {
                    window.location.href = "tel:+254768085555";
                  }}
                >
                  
                  <FontAwesomeIcon className="contact-icons" icon={faPhone} style={{color: "#000000",}} /> Call us
                </span>
                <span className=" "
                style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.location.href = "tel:+254768085555";
                  }}
                >
                  <b>+254768085555</b>
                </span>
              </div>
              <div className="d-flex  flex-column justify-content-start align-items-start px-5">
                <span style={{ cursor:"pointer" }}>
                <FontAwesomeIcon className="contact-icons" icon={faEnvelopeOpen} style={{color: "#000000",}} /> Email Us
                </span>
                
                <b>info@quicksava.co.ke</b>
              </div>
              <div className="d-flex flex-column justify-content-start align-items-start px-5">
                <span style={{ cursor: "pointer" }}>
                <FontAwesomeIcon className="contact-icons" icon={faGlobe} style={{color: "#000000",}} /> Website
                </span>
                <b>www.quicksava.co.ke</b>
              </div>
              <div className="d-flex flex-column justify-content-start align-items-start px-5">
                <span style={{ cursor: "pointer" }}>
                <FontAwesomeIcon className="contact-icons" icon={faStreetView} style={{color: "#000000",}} /> Visit Us
                </span>
                 <b>3rd floor , APA Arcade, Hurlingham Argwings Kodhek Road - Nairobi</b>
              </div>
            </div>
          </div>
        <div className="col-md-6 col-sm-12 col-lg-6">
          <div>
        <h3
              style={{
                fontSize: "1cm",
                letterSpacing: "0.05em",
                textAlign: "start",
                fontWeight: "bold",
                marginTop: "1cm",
              }}
              className="mb-0 mt-0 ml-5 mt-3 mb-3 px-2"
            >
              CONTACT US
            </h3>
            </div>
            <div  className={"contact-card"} style={{
              backgroundColor: "#e3e3e3",
              height:"max-content",
              borderRadius:"30px"
              
            }}
            >
              
        <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      id_no: "",
                    }}
                    onSubmit={handleNewLead}
                  >
                    <Form id="contact footer-contact" action="" method="post">
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className=" d-flex justify-content-between p-2 card-user-info w-100">
                        <div style={{                          
                        }}>
                          <fieldset>
                            <Field
                            style={{
                              borderRadius:"20px",
                              padding: "25px",
                              
                            }}
                              name="name"
                              type="text"
                              className="form-control px-5 "
                              id="name"
                              placeholder="Full Name"
                              required
                            />
                          </fieldset>
                        </div>
                          <div className="   ">
                          <fieldset>
                            <Field
                            style={{
                              borderRadius:"20px",
                              padding: "25px",
                            }}
                              name="msisdn"
                              type="text"
                              className="form-control px-5"
                              id="msisdn"
                              placeholder="Phone Number"
                              required
                            />
                          </fieldset>
                        </div>
                        </div>
                        <div className="p-2 w-100" style={{ borderRadius:"20px"}}>
                        <fieldset>
                            <Field style={{ borderRadius:"20px"}}
                              name="email"
                              type="text"
                              className="form-control py-4"
                              id="email"
                              pattern="[^ @]*@[^ @]*"
                              placeholder="Email Address"
                              required
                            />
                          </fieldset>
                        </div>                 
                        <div className="p-2 w-100" style={{ borderRadius:"20px", }}>
                          <fieldset>
                          <Field style={{ borderRadius:"20px"}}
                            as="select"
                            className="form-control "
                            id="Product"
                            name="product"
                            onChange={(e) => handleLoanType(e)}
                          >
                            <option value={loanType}>{loanType}</option>
                            <option value="logbook_loan">LogBook Loan</option>
                            <option value="import_loan">Import Loan</option>
                            <option value="asset_finance">Asset finance</option>
                          </Field>
                          </fieldset>
                        </div>
                        <div className="">
                          <fieldset>
                            <button type="submit" id="form-submit" className="filled-button px-5 py-3 "
                            style={{
                              border: "none",
                              marginBottom:"10px",
                              borderRadius:"30px",
                              backgroundColor:"orange"
                            }}>
                             <b> Submit Details </b> 
                            </button>
                          </fieldset>
                        </div>
                        <div>
                        </div>
                        </div>
                        </Form>
                        </Formik>
                        </div>
                        </div>
        </div>
        </div>
        </div>
        



        <footer>
          <div className="container mt-5" id="contact">
            <div className="row">
              <div className="col-md-3 footer-item">
                <h4>
                  <Link className="navbar-brand mt-5" to="/">
                    <img
                      src="./assets/images/logo.png"
                      alt="quick sava logo"
                      style={{ width: "4cm" }}
                    />
                  </Link>
                </h4>
                <ul className="social-icons">
                  <li>
                    <Link rel="nofollow" to="https://www.facebook.com/QuickSavaKE" target="_blank">
                      <i className="fa fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 footer-item ">
                <h3 className="">Contact</h3>
                <ul className="menu-list">
                  {/* <li><a href="#">quicksava</a></li> */}
                  {/* <li><a href="#">Customers</a></li>              */}
                  <li>
                    <a href="/services">products</a>
                  </li>
                  {/* <li><a href="#">More</a></li> */}
                </ul>
              </div>
              <div className="col-md-3 footer-item">
                <h3>Additional Pages</h3>
                <ul className="menu-list">
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  {/* <li><a href="#">How We Work</a></li> */}
                  {/* <li><a href="#">Quick Support</a></li> */}
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">Terms And Conditions</Link>
                  </li>

                  {/* <li><a href="#">Privacy Policy</a></li> */}
                </ul>
              </div>
              <div className="col-md-3 footer-item last-item">
                <h3>Contact Us</h3>
                <div className="contact-form">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      id_no: "",
                    }}
                    onSubmit={handleNewLead}
                  >
                    <Form id="contact footer-contact" action="" method="post">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <fieldset>
                            <Field
                              name="name"
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Name"
                              required
                            />
                          </fieldset>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <fieldset>
                            <Field
                              name="email"
                              type="text"
                              className="form-control"
                              id="email"
                              pattern="[^ @]*@[^ @]*"
                              placeholder="Email"
                              required
                            />
                          </fieldset>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <fieldset>
                            <Field
                              name="msisdn"
                              type="text"
                              className="form-control"
                              id="msisdn"
                              placeholder="Phone Number"
                              required
                            />
                          </fieldset>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <fieldset>
                            <Field
                              name="id_no"
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Id Number"
                              required=""
                            />
                          </fieldset>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                          <fieldset>
                            <label>Loan you are interested in</label>
                            <Field as="select" id="loanType" name="loan_product_id">
                              <option value="2">Quick LogBook Loan</option>
                              <option value="3">Quick Check Off Loan</option>
                              <option value="2">Quick Drive Loan</option>
                              <option value="3">Quick Biashara Loan</option>
                            </Field>
                          </fieldset>
                        </div>

                        <div className="col-lg-12">
                          <fieldset>
                            <button type="submit" id="form-submit" className="filled-button">
                              Send Details
                            </button>
                          </fieldset>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};


export default Contact;

// import Header from "./Header";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { getFromLocalStorage } from "../utils/local-storage";
// import PhoneIcon from '@mui/icons-material/Phone';
// import EmailIcon from '@mui/icons-material/Email';
// import LanguageIcon from '@mui/icons-material/Language';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import { Formik, Form, Field } from "formik";

// import Footer from "./Footer";

// const Contact = () => {
//   const user = getFromLocalStorage("user");
//   const location = useLocation();
//   const navigate = useNavigate();

//   return (
//     <div style={{ backgroundColor: "#fff" }} className="">
//       <div className="item item-20 ">
//         <Header />
//         <section className="" id="">
//           <div className="px-2">
//             <div className="col item-11">

//               <h4
//                 style={{ fontSize: "3em", letterSpacing: "-0.03em", font: "trueno" , zIndex: 1000 }}
//                 className="mb-0 mt-0 my-3 d-flex justify-content-center align-items-center"
//               >
//                 CONTACT  US
//               </h4>
//             </div>
//             <div className="row">
//             <div className="col-6">
//             <h3 className="fw-bold">Get in Touch
//               <PhoneIcon/>  <p>Call US</p> +254768085555
//               <EmailIcon/> <p>Email Us</p>info@quicksava.co.ke
//               <LanguageIcon/> <p>Website</p>www.quicksava.co.ke
//               <LocationOnIcon/><p>Visit US</p>3rd Floor, APA Arcade, Hurlingham Argwings Kodhek Road- Nairobi
//             </h3>
//             </div>
//             <div>
              
//             </div>
            
//             </div>
//           </div>
//         </section>
//       </div>

//       <Footer />
//     </div>
//   );
// };

// export default Contact;

