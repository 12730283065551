

// @mui material components
import Link from "@mui/material/Link";

// Layout Dashboard 2 MUI components
import LayoutButton from "../../components/LayoutButton";
import LayoutBox from "../../components/LayoutBox";
import LayoutTypography from "../../components/LayoutTypography";

// Layout Dashboard 2 MUI context
import { useLayoutController } from "../../context";

// Images
import icon from "../../assets/images/illustrations/icon-documentation.svg";

function SidenavFooter() {
  const [controller] = useLayoutController();
  const { miniSidenav, darkSidenav } = controller;

  return (
    <LayoutBox opacity={miniSidenav ? 0 : 1} sx={{ transition: "opacity 200ms linear" }}>
      <LayoutBox position="relative" textAlign="center">
        <LayoutBox component="img" src={icon} alt="sidebar_illustration" width="60%" />
        <LayoutBox
          width="100%"
          pb={2}
          px={2}
          color={darkSidenav ? "white" : "dark"}
          textAlign="center"
          lineHeight={0}
        >
          <LayoutTypography color="inherit" variant="h6">
            Need help?
          </LayoutTypography>
          <LayoutTypography color="inherit" variant="caption">
            Please check our docs or connect
          </LayoutTypography>
        </LayoutBox>
      </LayoutBox>
      <LayoutBox display="flex" flexDirection="column">
        <LayoutButton
          component={Link}
          href="terms-and-conditions"
          target="_blank"
          rel="noreferrer"
          color="dark"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
        >
          TermsAndCoditions
        </LayoutButton>
        <LayoutButton
          component={Link}
          href="tel:0701087777"
          target="_blank"
          rel="noreferrer"
          color="info"
          size="small"
          fullWidth
          mb={2}
        >
         Connect
        </LayoutButton>
      </LayoutBox>
    </LayoutBox>
  );
}

export default SidenavFooter;
