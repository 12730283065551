import { Formik, Form, Field,ErrorMessage } from "formik";
import { CircularProgress, Grid } from "@mui/material";
import LayoutTypography from "../../../../components/LayoutTypography";
import LayoutInput from "../../../../components/LayoutInput";
import LayoutButton from "../../../../components/LayoutButton";
import LayoutBox from "../../../../components/LayoutBox";
import LayoutFileInput from "../../../../components/LayoutInput/LayoutFileInput";
import validationSchema from "./businessValidation";
import PropTypes from "prop-types";
import { businessDocuments } from "./data";

// Assuming businessDocuments is imported from another file

function BusinessCompany({
  handleSaveCompany,
  savingCompany,
}) {
  // Constructing initialValues dynamically from businessDocuments
  const initialValues = {
    ...Object.fromEntries(
      businessDocuments.map((doc) => 
        [doc.name, ""]
      )
    ),
  };
  console.log("initial values", initialValues)


  console.log("validation schema", validationSchema)


  return (
    <>
      <Formik
        initialValues={initialValues} // Set initialValues dynamically
        validationSchema={validationSchema}
        onSubmit={(values) => handleSaveCompany(values)}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
                      <Grid container spacing={2}>

            <Grid item xs={12}>
              <LayoutTypography
                variant="h2"
                fontWeight="medium"
                textTransform="capitalize"
                textAlign="center"
              >
                Businesses
              </LayoutTypography>
            </Grid>

            {businessDocuments.map((doc, index) => (
              <Grid item xs={12} sm={6} key={index}>
             {doc.type === "text" ? (
                  <>
                    <Field
                      as={LayoutInput}
                      type="text"
                      multiline
                      rows={4}
                      name={doc.name}
                      // placeholder={doc.placeholder}
                      placeholder={
                        doc.placeholder && !doc.required
                          ? `${doc.placeholder} (Optional)`
                          : doc.placeholder
                      }
                      size="large"
                    />
                    <ErrorMessage
                      name={doc.name}
                      component="div"
                      style={{ color: "red", fontSize: "10px" }} // Error Message Styling
                    />
                  </>
                ) : (
                  <>
                    <LayoutFileInput
                      type="file"
                      name={doc.name}
                      handleChange={(event) => {
                        console.log("event data", event)
                        const name=event.target.name
                        const file = event.target.files[0]; // Add safeguard here
                        if (file) {
                          setFieldValue(name, file);
                        }
                      }}
                      placeholder={doc.placeholder}
                      required={doc.required}
                      size="large"
                    />
                    <ErrorMessage
                      name={doc.name}
                      component="div"
                      style={{ color: "red", fontSize: "10px" }} // Error Message Styling
                    />
                  </>
                )}
              </Grid>
            ))}

            <Grid item xs={12}>
              <LayoutButton
                variant="contained"
                color="info"
                type="submit"
                disabled={isSubmitting || savingCompany}
              >
                {savingCompany || isSubmitting ? <CircularProgress size={24} /> : "Save"}
              </LayoutButton>
            </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

BusinessCompany.propTypes = {
  savingCompany: PropTypes.bool.isRequired,
  handleSaveCompany: PropTypes.func.isRequired,
};

export default BusinessCompany;
