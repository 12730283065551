

// @mui material components
import Grid from "@mui/material/Grid";

// Layout Dashboard 2 MUI components
import LayoutBox from "../../components/LayoutBox";

// Layout Dashboard 2 MUI components
import MasterCard from "../../widgets/Cards/MasterCard";
import DefaultInfoCard from "../../widgets/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import BaseLayout from "../../layouts/billing/components/BaseLayout";
import PaymentMethod from "../../layouts/billing/components/PaymentMethod";
import Invoices from "../../layouts/billing/components/Invoices";
import BillingInformation from "../../layouts/billing/components/BillingInformation";
import Transactions from "../../layouts/billing/components/Transactions";

function Billing() {
  return (
    <BaseLayout stickyNavbar>
      <LayoutBox mt={4}>
        <LayoutBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} xl={6}>
                  <MasterCard number={4562112245947852} holder="jack peterson" expires="11/22" />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    icon="account_balance"
                    title="DEBIT"
                    description="VIA MPESA"
                    value="+KES2000"
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    icon="paypal"
                    title="CREDIT"
                    description="VIA MPESA"
                    value="KES455.00"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PaymentMethod />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Invoices />
            </Grid>
          </Grid>
        </LayoutBox>
        <LayoutBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <BillingInformation />
            </Grid>
            <Grid item xs={12} md={5}>
              <Transactions />
            </Grid>
          </Grid>
        </LayoutBox>
      </LayoutBox>
    </BaseLayout>
  );
}

export default Billing;
