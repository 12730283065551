import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Layout Dashboard 2 MUI components
import LayoutBox from "../components/LayoutBox";

// Layout Dashboard 2 MUI example components
import Sidenav from "../widgets/Sidenav";
import Configurator from "../widgets/Configurator";

// Layout Dashboard 2 MUI themes
import theme from "../assets/theme";
import themeRTL from "../assets/theme/theme-rtl";
import themeDark from "../assets/theme-dark";
import themeDarkRTL from "../assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Layout Dashboard 2 MUI routes
import routes from "../routes";

// Layout Dashboard 2 MUI contexts
import {
  useLayoutController,
  setMiniSidenav,
  setOpenConfigurator,
  setLayout,
} from "../context";

// Images
import brand from "../assets/images/logo.png";
import brandSm from "../assets/images/logoSm.png";
import brandDark from "../assets/images/logo.png";

// Icon Fonts
import "../assets/css/nucleo-icons.css";
import "../assets/css/nucleo-svg.css";

export default function Profile() {
  const [controller, dispatch] = useLayoutController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    darkSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const ignoreRoutes = [
    "services",
    "loan-calculator",
    "about",
    "referal",
    "contact",
    "terms-and-conditions",
    "auth",
    "register",
    "verify", 
    "autologbook-loans",
    "checkoff-loans",
    "import-loans",
    "details", 
    "personal-loans",
    "career"
  ];

  useEffect(() => {
    console.log("pathname information", pathname);
    if (pathname === "/") {
      setLayout(dispatch, "home");
    } else if (ignoreRoutes.some((route) => pathname.includes(route))) {
      setLayout(dispatch, "home");
    } else if (pathname.includes("dashboard")) {
      setLayout(dispatch, "dashboard");
    } else {
      setLayout(dispatch, "dashboard");
    }
  }, [pathname]);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      // console.log("routes information", route);
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <LayoutBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </LayoutBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={miniSidenav? brandSm : darkSidenav || darkMode ? brand : brandDark}
              brandName="Quicksava Dashboard"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="/dashboard" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={miniSidenav? brandSm :darkSidenav || darkMode ? brand : brandDark}
            brandName=""
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      {/* <Overview/> */}
      <Routes>
        {/* {console.log("route here", getRoutes(routes))} */}
        {getRoutes(routes)}
        <Route path="/dashboard" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
