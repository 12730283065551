import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import loans from "../../assets/data/loans.json"; // Adjust the path to your actual location
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getFromLocalStorage } from "../../utils/local-storage";
import { useSelector } from "react-redux";

const LoanSlider = () => {
  const[user, setUser]=useState(getFromLocalStorage("user"));
  const userData = useSelector((state)=>state?.users?.user)

  useEffect(() => {
    setUser(userData ?? getFromLocalStorage("user"));

  },[])


  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide:2,
    speed: 300,
    arrows:true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          // centerMode: true,
          // centerPadding: 40,
          infinite: true,
          // adaptiveHeight: true,
          // dots: true,
          autoplay: false,
          pauseOnHover: true,
          focusOnSelect: true,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-100 px-4 ">
      <Slider {...settings}>
        {loans.map((loan) => (
          <div key={loan.id} className=" mb-5 px-2">
            <div className="service-item">
              <div className="image-container">
                <img
                  src={loan.image}
                  alt={loan.title}
                  onClick={() => navigate(loan?.readMoreLink)}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="content-container text-dark">
                <h5 className="my-2 mb-2 title-underline">{loan.title}</h5>
                <p className="text-dark mt-2 service-item-text">{loan.summary}</p>
              </div>
              <button
                className="apply-button filled-button btn"
                onClick={() => userData?navigate(`/profile?loan_id=${loan?.id}&tab=3`):navigate("/auth")}
              >
                <strong>Apply Now</strong>
              </button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LoanSlider;
