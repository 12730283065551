import * as Yup from "yup";
import { businessDocuments } from "./data";

// Create a Yup schema based on businessDocuments
const validationSchema = Yup.object().shape(
  businessDocuments.reduce((acc, doc) => {
    if (doc.type === "file") {
      // If the field is a file input and is required, validate it
      acc[doc.name] = doc.required
        ? Yup.mixed().required(`${doc.placeholder} is required`)
        : Yup.mixed();
    } else if (doc.type === "text") {
      // If it's a text field, validate as a string
      acc[doc.name] = Yup.string()
        .nullable()
        .max(500, "Maximum length is 500 characters")
        .optional(); // Make it optional for text fields
    }
    return acc;
  }, {})
);

export default validationSchema;
