import Header from "./Header";
import {  useNavigate } from "react-router-dom";
import CareerMenuButton from "./LayoutButton/CareerMenuButton";
import { useState } from "react";



import Footer from "./Footer";

const Career = () => {
  const navigate = useNavigate();

  const [loanType, setLoanType] = useState("Product");
const handleLoanType = (e) => {
  setLoanType(e.target.value);
};

  return (
    <div style={{ backgroundColor: "#fff" }} className="">
      <div className="item item-20 ">
        <Header />
        <section className="" id="">
          <div className="px-2">
            <div className="col item-11">

              <h4
                style={{ fontSize: "3em", letterSpacing: "-0.03em", font: "trueno" , zIndex: 2 }}
                className="mb-0 mt-0 my-3 d-flex justify-content-center align-items-center"
              >
                JOIN OUR TEAM
              </h4>
            </div>

            <div className="col">
              <div className="section-heading service_heading text-dark">
                <h3
                  style={{
                    fontSize: ".6cm",
                    letterSpacing: "0.05em",
                    fontWeight: "normal",
                    marginTop: "1cm",
                  }}
                  className="mb-0"
                >
                  Whether you are a recent graduate starting your career or a seasoned professional
                  aiming to accelerate your career growth,
                  <br />
                  Quicksava is here to support and enhance your journey.
                  <br />
                  At Quicksava we are deeply committed to a core set of values, whichare shared
                  across our shareholders,
                  <br />
                  board management and staff. We strive to ensure these values are consistently
                  refrected in our teams actions and are <br />
                  and are at the heart of our brand <br />
                  If you are interested in joining our dynamic environment focussed on learning
                  development,and growth driven by values, please send yourdetails and resume to
                  careers@quicksava.co.ke. We will reach out to you when an opportunity becames
                  available.
                </h3>
                
                <div 
                style={{
                  marginTop: "30px",
                  padding:"0px 3rem"
                }} >
                 <CareerMenuButton />  
                </div>
               
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Career;
