import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

// Styled Button (similar to an input field)
const MenuButton = styled(Button)`
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  line-height: 1.5;
  padding: 2px 20px;
  border-bottom: 3px solid black;
  background: none;
  cursor: pointer;
  border-radius: 0px;
  display: flex;
  justify-content: space-between; /* Ensures space between text and icon */
  align-items: center;
  width: 100%; /* Adjust width as needed */
  text-align: left; /* Align text to the left */
  color: black; /* Set text color to black */
  &:focus {
    outline: none; /* Removes focus outline */
  }
`;

// Styled Menu
const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0px;
    margin-top: 8px;
    width: auto; /* Set the width to match MenuButton */
    min-width: 67%; /* Ensures the menu spans at least the width of the MenuButton */
    @media (max-width: 991px) {
      width: 90%;
    }
  }
`;

// Styled MenuItem to make it the same width as the MenuButton
const StyledMenuItem = styled(MenuItem)`
  width: 100%; /* Make MenuItem take the full width of the parent Menu */
  border-radius: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  width: 90%; /* Ensures full width */
  border-radius: 0px;
  gap: 10px;
  /* Media query for mobile screens with a max-width of 991px */
  @media (max-width: 991px) {
    justify-content: center;
    flex-direction: column; /* Change to column layout on mobile */
    align-items: flex-start; /* Align items to the start when in column mode */
  }
`;

export default function MenuIntroduction() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState("Select a position"); // Default text for button
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (menuItem) => {
    setSelectedItem(menuItem); // Set selected item
    console.log(`Selected: ${menuItem}`);
    handleClose();
  };

  return (
    <div>
      <ButtonContainer>
        {/* Label for the menu */}
        <h5 style={{ whiteSpace: "nowrap" }}>
          <b>Available Positions</b>
        </h5>

        {/* Menu Button with dropdown */}
        <MenuButton onClick={handleClick}>
          <span>{selectedItem}</span> {/* Show selected item in button */}
          <FontAwesomeIcon
            icon={faCaretDown}
            style={{ color: "#000000", cursor: "pointer" }}
            className="icon-size"
          />
        </MenuButton>
        <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <StyledMenuItem onClick={() => handleMenuClick("No Careers at the moment")}>
            No Careers at the moment
          </StyledMenuItem>
          {/* Add more menu items here */}
        </StyledMenu>
      </ButtonContainer>
    </div>
  );
}

MenuIntroduction.propTypes = {
  ownerState: PropTypes.object,
};
