import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useLayoutController } from "../../context";
import CustomFileInputRoot from "./LayoutFileInputRoot";
import { Button } from "@mui/material";

const LayoutFileInput = ({
  name,
  disabled,
  handleChange,
  placeholder,
  error,
  helperText,
  required,
  previewUrl, // Add the previewUrl prop here
  ...rest
}) => {
  const [controller] = useLayoutController();
  const { darkMode } = controller;
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null); // Create a reference for the file input

  const ownerState = { disabled, darkMode };

  useEffect(() => {
    if (previewUrl) {
      setPreview(previewUrl); // If previewUrl is passed, set it as the preview
    }
  }, [previewUrl]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
      const fileType = selectedFile.type;

      if (fileType.startsWith("image/") || fileType === "application/pdf") {
        setPreview(URL.createObjectURL(selectedFile));
      } else {
        setPreview(null);
      }
      handleChange({
        target: {
          name: event.target.name,
          type: "file",
          files: event.target.files,
        },
      });
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setPreview(null);

    // Reset the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    handleChange({
      target: {
        name,
        type: "file",
        files: [],
      },
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <CustomFileInputRoot
        type="file"
        name={name}
        ref={fileInputRef} // Attach the ref to the input
        onChange={handleFileChange}
        disabled={disabled}
        ownerState={ownerState}
        {...rest}
      />

      {!file && !preview && (
        <div
          style={{
            marginTop: "10px",
            padding: "10px",
            border: "1px dashed #bbb",
            borderRadius: "6px",
            color: "#bbb",
            fontStyle: "italic",
            textAlign: "center",
          }}
        >
          {(placeholder ?? name) ? `${placeholder ?? name}${!required ? " (Optional)" : ""}` : "No file selected"}
        </div>
      )}

      {file || preview ? (
        <div
          style={{
            marginTop: "10px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          {preview ? (
            preview.startsWith("http") ? ( // Check if it's a URL
              <img
                src={preview}
                alt="Preview"
                style={{ width: "120px", height: "120px", objectFit: "cover", borderRadius: "6px" }}
              />
            ) : (
              file.type.startsWith("image/") ? (
                <img
                  src={preview}
                  alt="Preview"
                  style={{ width: "120px", height: "120px", objectFit: "cover", borderRadius: "6px" }}
                />
              ) : (
                <embed src={preview} type="application/pdf" width="200px" height="250px" />
              )
            )
          ) : (
            <p>{file.name}</p>
          )}

          <div style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
            <Button variant="contained" component="label" size="small" color="primary">
              Update
              <input type="file" hidden onChange={handleFileChange} />
            </Button>

            <Button variant="contained" size="small" color="secondary" onClick={handleRemoveFile}>
              Remove
            </Button>
          </div>
        </div>
      ) : null}

      {/* Display helper text if there's an error */}
      {error && helperText && (
        <div style={{ color: "red", fontSize: "12px", marginTop: "4px" }}>{helperText}</div>
      )}
    </div>
  );
};

LayoutFileInput.defaultProps = {
  disabled: false,
  placeholder: "No file selected",
  helperText: "", // Default value for helperText
  error: false, // Default error state is false
  required: false, // Default to false, so label will show "Optional" by default
  previewUrl: null, // Default to null if no URL is passed
};

LayoutFileInput.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string, // Add prop type for helperText
  error: PropTypes.bool, // Add prop type for error
  required: PropTypes.bool, // Add prop type for required
  previewUrl: PropTypes.string, // Add prop type for previewUrl (URL for image or PDF)
};

export default LayoutFileInput;
